/**
 * Created by hao.cheng on 2017/4/16.
 */
import React from 'react'
import { Form, Icon, Input, Button, Modal } from 'antd'
import { PwaInstaller } from '../widget'
import { connectAlita } from 'redux-alita'
import { RouteComponentProps } from 'react-router'
import { FormProps } from 'antd/lib/form'
import umbrella from 'umbrella-storage'
import { message } from 'antd'
import { authLogin, validCodeBase64 } from '../../service'
import { Link } from 'react-router-dom'
import Register from './Register'
import RetrievePwd from './RetrievePwd'

const FormItem = Form.Item
type LoginProps = {
  setAlitaState: (param: any) => void
  auth: any
  buttonTitle: any
  buttonType: any
  slot?: any
} & RouteComponentProps &
  FormProps

type LoginStates = {
  validImg: any
  user: any
  showLogin: boolean
}

class Login extends React.Component<LoginProps, LoginStates> {
  constructor(props: any) {
    super(props)
    this.getValidCode = this.getValidCode.bind(this)
    this.state = {
      validImg: '',
      user: {},
      showLogin: props.showLogin,
    }
  }
  componentDidMount() {
    const { setAlitaState } = this.props
    setAlitaState({ stateName: 'auth', data: null })
    this.getValidCode()
  }

  componentDidUpdate(prevProps: LoginProps) {
    // React 16.3+弃用componentWillReceiveProps
    const { auth: nextAuth = {}, history } = this.props
    // const { history } = this.props;
    if (nextAuth.data && nextAuth.data.uid) {
      // 判断是否登陆
      umbrella.setLocalStorage('user', nextAuth.data)
      history.push('/')
    }
  }
  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        const { setAlitaState } = this.props
        const params = {
          ...values,
          isValidateCode: 1,
          exterIP: '',
          lngLat: '',
          accountType: 1,
        }
        const res = await authLogin(params)
        if (res.success) {
          const user = res.data[0]
          this.setState({
            user: user,
          })
          umbrella.setLocalStorage('user', user)
          umbrella.setLocalStorage('token', user.Authorization || '')
          user && setAlitaState({ stateName: 'user', data: user })
          this.setState({
            showLogin: false,
          })
          window.location.reload()
        } else {
          message.warn(res.msg)
        }
      }
    })
  }

  getValidCode = async () => {
    const res = await validCodeBase64()
    this.setState({
      validImg: res,
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form!
    const { buttonType } = this.props
    return (
      <div>
        <Modal
          width={420}
          visible={this.state.showLogin}
          footer={null}
          onOk={() => {
            this.setState({
              showLogin: false,
            })
          }}
          onCancel={() => {
            this.setState({
              showLogin: false,
            })
          }}
        >
          <div className="login ">
            <div className="login-form">
              <div className="login-logo">
                <span>培训系统用户版</span>
                <PwaInstaller />
              </div>
              <Form onSubmit={this.handleSubmit} style={{ maxWidth: '300px' }}>
                <FormItem>
                  {getFieldDecorator('phone', {
                    rules: [
                      { required: true, message: '请输入手机!' },
                      { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机!' },
                    ],
                  })(
                    <Input
                      prefix={<Icon type="user" style={{ fontSize: 13 }} />}
                      placeholder="手机"
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('pwd', {
                    rules: [{ required: true, message: '请输入密码!' }],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
                      type="password"
                      placeholder="密码"
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('validateCode', {
                    rules: [{ required: true, message: '请输入验证码!' }],
                  })(
                    <div className="flex-h">
                      <Input
                        prefix={<Icon type="check" style={{ fontSize: 13 }} />}
                        placeholder="验证码"
                      />
                      <img
                        height="28px"
                        src={this.state.validImg}
                        alt=""
                        onClick={this.getValidCode}
                      />
                    </div>
                  )}
                </FormItem>
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    style={{ width: '100%' }}
                  >
                    登录
                  </Button>
                </FormItem>
                <FormItem>
                  <Register className="login-form-button" />
                </FormItem>
                <FormItem>
                  <RetrievePwd className="login-form-button" />
                </FormItem>
              </Form>
            </div>
          </div>
        </Modal>

        {!this.props.slot && (
          <Button
            type={buttonType || 'primary'}
            onClick={() => {
              this.setState({
                showLogin: true,
              })
            }}
          >
            {this.props.buttonTitle || '登录'}
          </Button>
        )}
        {this.props.slot && (
          <div
            onClick={() => {
              this.setState({
                showLogin: true,
              })
            }}
          >
            {this.props.slot}
          </div>
        )}
      </div>
    )
  }
}

export default connectAlita(['auth'])(Form.create()(Login))
