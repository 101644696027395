/**
 * Created by hao.cheng on 2025/02/20.
 */
import React from 'react'
import { Form, Icon, Input, Button, Card, Modal } from 'antd'
import { connectAlita } from 'redux-alita'
import { RouteComponentProps } from 'react-router'
import { FormProps } from 'antd/lib/form'
import umbrella from 'umbrella-storage'
import { message } from 'antd'
import { reprovision, validCodeBase64, apiSendSmsCode } from '../../service'

const FormItem = Form.Item
type RetrievePwdProps = {
  setAlitaState: (param: any) => void
  auth: any
} & RouteComponentProps &
  FormProps

type RetrievePwdStates = {
  validImg: any //  验证码
  user: any
  hasSendSms: Boolean // 是否发送短信验证码
  leftTime: any // 倒计时
  inter: any // 倒计时器
  show: boolean // 弹窗
}

class RetrievePwd extends React.Component<RetrievePwdProps, RetrievePwdStates> {
  constructor(props: any) {
    super(props)
    this.getValidCode = this.getValidCode.bind(this) // 获取手机验证码
    this.setCountDown = this.setCountDown.bind(this) // 重置读秒
    this.startCountDown = this.startCountDown.bind(this) // 开始读秒
    this.state = {
      validImg: '',
      user: {},
      hasSendSms: false,
      leftTime: 60,
      inter: null,
      show: false,
    }
  }

  componentDidMount() {
    const { setAlitaState } = this.props
    setAlitaState({ stateName: 'auth', data: null })
    // 获取手机验证码
    this.getValidCode()
  }

  componentDidUpdate(prevProps: RetrievePwdProps) {
    const { auth: nextAuth = {}, history } = this.props
    if (nextAuth.data && nextAuth.data.uid) {
      // 判断是否登陆
      umbrella.setLocalStorage('user', nextAuth.data)
      history.push('/')
    }
  }

  // 提交手机账户对应密码
  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        const params = {
          ...values,
          isValidateCode: 1, // 是否校验图形验证码 1:是
        }
        const res = await reprovision(params)
        if (res.success) {
          message.success('找回密码成功')
          this.setState({
            show: false,
          })
          return
        } else {
          // 获取手机验证码
          this.getValidCode()
          message.error(res.msg || '找回密码失败')
        }
      }
    })
  }

  // 获取手机验证码
  getValidCode = async () => {
    const res = await validCodeBase64()
    this.setState({
      validImg: res,
    })
  }

  // 获取图形验证码
  sendSmsCode = async (phone: String) => {
    const res = await apiSendSmsCode({ phone: phone, type: 3 })
    if (res.success) {
      message.success('验证码发送成功')
      this.setState({
        hasSendSms: true,
      })
      // 开始读秒
      this.startCountDown()
    } else {
      message.error(res.msg || '验证码发送失败')
    }
  }

  // 开始读秒
  startCountDown() {
    // 重置读秒
    const interval = setInterval(this.setCountDown, 1000, 60)
    this.setState({ inter: interval })
  }

  // 重置读秒
  setCountDown() {
    const leftTime = this.state.leftTime - 1
    if (leftTime > 0) {
      setTimeout(() => {
        this.setState({
          leftTime: leftTime,
        })
      }, 0)
    } else if (leftTime === 0) {
      clearInterval(this.state.inter)
      setTimeout(() => {
        this.setState({
          hasSendSms: false,
          leftTime: 60,
        })
      }, 0)
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form!
    return (
      <div>
        <Modal
          width={420}
          visible={this.state.show}
          footer={null}
          onOk={() => {
            this.setState({
              show: false,
            })
          }}
          onCancel={() => {
            this.setState({
              show: false,
            })
          }}
        >
          <div className="reprovision">
            <div className="reprovision-form">
              <div className="login-logo">
                <span>找回密码</span>
              </div>
              <Form onSubmit={this.handleSubmit} style={{ maxWidth: '300px' }}>
                <FormItem>
                  {getFieldDecorator('phone', {
                    rules: [
                      { required: true, message: '请输入手机!' },
                      { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机!' },
                    ],
                  })(
                    <div className="flex-h">
                      <Input
                        prefix={<Icon type="phone" style={{ fontSize: 13 }} />}
                        placeholder="手机"
                      />
                      {!this.state.hasSendSms && (
                        <Button
                          type="link"
                          className="login-form-button"
                          style={{ width: '30%' }}
                          onClick={() => {
                            const phone = this.props.form?.getFieldValue('phone')
                            this.sendSmsCode(phone)
                          }}
                        >
                          发送验证码
                        </Button>
                      )}
                      {this.state.hasSendSms && (
                        <Button
                          type="link"
                          disabled
                          className="login-form-button"
                          style={{ width: '40%' }}
                        >
                          已发送验证码({this.state.leftTime}s)
                        </Button>
                      )}
                    </div>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('smsCode', {
                    rules: [{ required: true, message: '请输入短信验证码!' }],
                  })(
                    <Input
                      prefix={<Icon type="code" style={{ fontSize: 13 }} />}
                      placeholder="短信验证码"
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('validateCode', {
                    rules: [{ required: true, message: '请输入验证码!' }],
                  })(
                    <div className="flex-h">
                      <Input
                        prefix={<Icon type="check" style={{ fontSize: 13 }} />}
                        placeholder="验证码"
                      />
                      <img
                        height="28px"
                        src={this.state.validImg}
                        alt=""
                        onClick={this.getValidCode}
                      />
                    </div>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('pwd', {
                    rules: [
                      { required: true, message: '请输入新密码!' },
                      {
                        pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]{6,18}$/,
                        message: '密码长度为6-18位，必须由字母、数字组成',
                      },
                    ],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
                      type="password"
                      placeholder="新密码"
                    />
                  )}
                </FormItem>
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    style={{ width: '100%' }}
                  >
                    重置密码
                  </Button>
                </FormItem>
              </Form>
            </div>
          </div>
        </Modal>
        <Button
          type="link"
          block
          onClick={() => {
            this.setState({
              show: true,
            })
          }}
        >
          找回密码
        </Button>
      </div>
    )
  }
}

export default connectAlita(['auth'])(Form.create()(RetrievePwd))
