/*
 * File: Copyright.tsx
 * Desc: 版权信息
 * File Created: 2020-04-12 22:50:33
 * Author: chenghao
 * ------
 * Copyright 2020 - present, karakal
 */
import React from 'react'
import { COPY_RIGHT, ICP, OPER_BOOK_CLIENT, OPER_BOOK_ADMIN } from './../../service/config'

const Copyright = () => {
  return (
    <div className="footer">
      <div>
        ©{new Date().getFullYear()} 版权所有 {COPY_RIGHT}
      </div>
      {/* <div>©{new Date().getFullYear()} 版权所有 浙江省混凝土协会</div> */}
      <div className="mt-s">
        <a href="https://beian.miit.gov.cn/" target="_blank">
          {ICP}
        </a>
        <span className="ml-m">
          {OPER_BOOK_CLIENT && (
            <a href={OPER_BOOK_CLIENT} target="blank">
              【操作手册-学员端】
            </a>
          )}
          {/*
          {OPER_BOOK_ADMIN && <a href={OPER_BOOK_ADMIN} target='blank'>【操作手册-管理端】</a>}
          */}
        </span>
      </div>
    </div>
  )
}

export default Copyright
