import * as React from 'react'
import Breadcrumb from '../Breadcrumb'
import { Button, Tag, Collapse, message, Steps, Tabs } from 'antd'
import { planOne, trainOne, favorItem, favorItemDel, createOrderAuto } from './../../service/index'
import { IMAGE_SERVER } from '../../service/config'
import CourseCell from '../course/CourseCell'
import { Link } from 'react-router-dom'
import { hasLogin } from '../../utils'
import umbrella from 'umbrella-storage'
import course_bg1 from './../../style/imgs/course_bg1.png'
import Login from '../pages/Login'
import moment from 'moment'

const { Step } = Steps
const { Panel } = Collapse
const { TabPane } = Tabs

export interface ICourseDetailProps {}
interface ICourseDetailStates {
  planId: any
  plan: any
  loginStu: any
}

export default class CourseDetail extends React.Component<ICourseDetailProps, ICourseDetailStates> {
  constructor(props: any) {
    super(props)
    this.state = {
      planId: props.query.id,
      plan: {},
      loginStu: null,
    }
  }
  componentDidMount() {
    let stu = null
    let user = umbrella.getLocalStorage('user')
    if (user && user.studentJson) {
      stu = user.studentJson[0]
    }
    this.setState({
      loginStu: stu,
    })
    this.getData()
  }

  async getData() {
    const params = { planId: this.state.planId }
    const res = await planOne(params)

    if (res.success) {
      const plan = res.data[0]
      this.setState({ plan: plan })
    }
  }

  async getTrainDetail(itemId: any) {
    const params = { itemId: itemId }
    const res = await trainOne(params)

    if (res.success) {
      const train = res.data[0]
      return train
    }
  }

  async favorItem(itemId: any, favor: boolean) {
    let res = null
    let del = ''
    const params = { favoriteTypeId: itemId, favoriteType: 3 }
    if (favor) {
      res = await favorItem(params)
    } else {
      res = await favorItemDel(params)
      del = '取消'
    }

    if (res.success) {
      message.success(del + '收藏成功')
      this.getData()
    } else {
      message.success(del + '收藏失败')
    }
  }

  async startStudy() {
    let res = null
    const params = {
      orderType: 3,
      orderTitle: this.state.plan.title,
      orderTypeId: this.state.planId,
    }
    res = await createOrderAuto(params)
    if (res.success) {
      this.getData()
    } else {
      message.error(res.msg)
    }
  }

  public render() {
    const { plan, loginStu } = this.state
    let allTrainIds = []
    if (plan.planItemJson) {
      allTrainIds = plan.planItemJson.map((train: any) => train.itemId)
    }

    const customPanelStyle = {
      background: '#fff',
      borderRadius: 4,
      marginBottom: 24,
      border: 0,
      overflow: 'hidden',
    }

    const startDate = new Date(plan.enrollStartDate)
    const endDate = new Date(plan.enrollEndDate)
    const now = new Date()
    const isLate = moment(moment(plan.enrollEndDate).format('YYYY-MM-DD')).isBefore(
      moment(moment().format('YYYY-MM-DD'))
    )
    const isOverDate = now.getTime() < startDate.getTime() || isLate
    return (
      <div>
        <div className="app_layout_main">
          <Breadcrumb />
        </div>
        <div className="app_layout_main">
          <div className="course_detail_body">
            <div className="flex">
              <div className="course-box">
                {plan.coverDirectURL && (
                  <img className="img" src={IMAGE_SERVER + plan.coverDirectURL} alt="" />
                )}
                {!plan.coverDirectURL && <img className="img" src={course_bg1} alt="" />}
              </div>
              <div className="course-box ml-m">
                <div className="title">
                  {plan.title}
                  <Tag color="orange" className="ml-s">
                    计划
                  </Tag>
                  {plan.categoryName && <Tag color="blue">{plan.categoryName} </Tag>}
                </div>
                <div className="detail mt-s">
                  <span className="text-gray">
                    <span className="text-yellow mr-s">
                      {plan.planItemJson && plan.planItemJson.length}
                    </span>
                    项目
                  </span>
                  <span className="mt-s ml-m text-gray">
                    <span className="text-yellow mr-s">{plan.popularValue}</span>人气值
                  </span>
                  <div className="mt-s text-gray">
                    报名时间：
                    <Tag color={isOverDate ? 'gold' : 'green'}>{plan.enrollStartDate}</Tag>/ {'  '}
                    <Tag color={isOverDate ? 'gold' : 'green'}>{plan.enrollEndDate} </Tag>
                  </div>
                  <div className="flex mt-s">
                    <div className="text-gray">
                      <span style={{ textDecoration: 'line-through' }}>￥{plan.standardPrice}</span>
                      <span className="price text-2x text-yellow">￥{plan.prefePrice}</span>
                    </div>
                  </div>
                  <div className="flex-vcenter mt-m ">
                    {!hasLogin() && <Login buttonTitle="去报名" />}
                    {hasLogin() &&
                      plan.planId &&
                      !isOverDate &&
                      plan.isEnroll != 1 &&
                      loginStu &&
                      plan.stuLevel > loginStu.stuLevel && (
                        <Link
                          className="mr-m"
                          to={'/app/train/orderConfirm?type=plan&id=' + this.state.planId}
                          color="gray"
                        >
                          <Button type="primary" block>
                            报名学习
                          </Button>
                        </Link>
                      )}
                    {hasLogin() &&
                      plan.planId &&
                      !isOverDate &&
                      plan.isEnroll != 1 &&
                      loginStu &&
                      plan.stuLevel <= loginStu.stuLevel && (
                        <Button
                          type="primary"
                          className="mr-m"
                          block
                          onClick={() => {
                            this.startStudy()
                          }}
                        >
                          开始学习
                        </Button>
                      )}
                    {hasLogin() && plan.planId && isOverDate && (
                      <Button type="primary" disabled block>
                        此计划已过期
                      </Button>
                    )}
                    {hasLogin() && plan.planId && (
                      <Button
                        type="primary"
                        ghost
                        icon="star"
                        onClick={() => {
                          this.favorItem(plan.planId, plan.isFavorite === '0')
                        }}
                      >
                        {plan.isFavorite === '1' ? '取消收藏' : '收藏'}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <span
              className="course-desc"
              dangerouslySetInnerHTML={{ __html: plan.abstract }}
            ></span>
          </div>
          <Collapse activeKey={allTrainIds} bordered={false} onChange={() => {}}>
            {plan.planItemJson &&
              plan.planItemJson.map((train: any, index: any) => {
                let status: any = 'process'
                switch (train.learnStatus) {
                  case 1:
                    status = 'process'
                    break
                  case 2:
                    status = 'wait'
                    break
                  case 3:
                    status = 'finish'
                    break
                  default:
                    status = 'finish'
                    break
                }
                return (
                  <Panel header={train.title} key={train.itemId} style={customPanelStyle}>
                    <div className="flex-wrapper">
                      {train.itemCourJson &&
                        train.itemCourJson.map((course: any) => (
                          <CourseCell
                            course={course}
                            type={3}
                            typeId={this.state.planId}
                            isEnroll={plan.isEnroll == 1}
                            modal
                          />
                        ))}
                    </div>
                  </Panel>
                )
              })}
          </Collapse>
        </div>
      </div>
    )
  }
}
