import * as React from 'react'
import {
  Modal,
  Button,
  Input,
  Form,
  Row,
  Col,
  Radio,
  Select,
  Card,
  List,
  message,
  Upload,
  Icon,
} from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import CompInput from '../widget/CompInput'
import PhoneInput from '../widget/PhoneInput'

import { studentList, orderStuAddMod } from '../../service'
import GlobalRegionCascader from '../widget/GlobalRegionCascader'
import { BASE_URL } from './../../service/config'
import moment from 'moment'

const { Option } = Select
export interface IApplyStuEditProps extends FormComponentProps {
  type: string
  item?: any
  courseId?: any
  onCourseChange: (course: any) => void
  isFile: any
  isDelEdit: any
  isCcieNo: any
}
type IEditStuStates = {
  regiProvinId: any
  regiCityId: any
  provinName: any
  cityName: any
  compId: any
  compName: any
  compCode: string | number
  address: any
  visible: any
  attachList: any[]
  fileList: any[]
  countrName: any
  regiCountrId: any
  gender: any
  previewImage: any
  previewVisible: any
  phone: any
}

class ApplyStuEdit extends React.Component<IApplyStuEditProps, IEditStuStates> {
  componentDidMount() {
    if (this.props.item) {
      this.props.form.setFieldsValue({
        ...this.props.item,
      })
    }
    if (this.props.item && this.props.item.files) {
      let files: any[] = []
      let propFiles: any = []
      if (this.props.item.files.length > 0) {
        propFiles = this.props.item.files.split(',')
      }
      propFiles.forEach((url: any, index: any) => {
        const startIndex = url.indexOf('/upload/')
        const title = url.substring(startIndex + url.length)
        files.push({
          uid: index,
          name: title,
          status: 'done',
          url: url,
        })
      })
      this.setState({
        fileList: files,
      })
    }
  }

  state = {
    visible: false,
    compId: this.props.item ? this.props.item.compId : '',
    compName: this.props.item ? this.props.item.compName : '',
    compCode: this.props.item ? this.props.item.compCode : '',
    address: this.props.item ? this.props.item.address : '',
    regiProvinId: this.props.item ? this.props.item.regiProvinId : '',
    regiCityId: this.props.item ? this.props.item.regiCityId : '',
    provinName: this.props.item ? this.props.item.provinName : '',
    cityName: this.props.item ? this.props.item.cityName : '',
    regiCountrId: this.props.item ? this.props.item.regiCountrId : '',
    countrName: this.props.item ? this.props.item.countrName : '',
    gender: this.props.item ? this.props.item.gender : '',
    phone: this.props.item ? this.props.item.phone : '',
    attachList: [],
    previewImage: '',
    previewVisible: false,
    fileList: [],
    flag: Math.random() + 1,
  }
  timerId: any = null
  timerPhone: any = null
  stuMap: any = {}
  compMap: any = {}

  handleOk = async (_e: any) => {
    if (this.props.type === 'edit') {
      // 编辑
      const values = this.props.form.getFieldsValue()
      console.log('values0:' + JSON.stringify(values))

      if (!values.phone) {
        message.error('手机不能为空')
        return
      }
      if (!values.stuName) {
        message.error('姓名不能为空')
        return
      }
      if (!values.gender) {
        message.error('性别不能为空')
        return
      }
      if (!values.cardType) {
        message.error('证件类型不能为空')
        return
      }
      if (!values.cardID) {
        message.error('证件号码不能为空')
        return
      }
      if (!values.compName) {
        message.error('企业名称不能为空')
        return
      }
      if (!values.compCode) {
        message.error('税号不能为空')
        return
      }
      if (!values.address) {
        message.error('公司地址不能为空')
        return
      }

      let files = ''
      if (this.state.attachList.length > 0) {
        this.state.attachList.forEach((attach: any) => {
          files += attach.baseServiURL0 + attach.directURL + ','
        })
        files = files.substr(0, files.length - 1)
      }
      const stu = {
        ...this.props.item,
        ...values,
        regiProvinId: this.state.regiProvinId,
        regiCityId: this.state.regiCityId,
        provinName: this.state.provinName,
        cityName: this.state.cityName,
        compId: this.state.compId,
        compName: this.state.compName,
        phone: values.phone,
        countrName: this.state.countrName,
        regiCountrId: this.state.regiCountrId,
        files: files.length > 0 ? files : undefined,
      }
      console.log('stu0:' + JSON.stringify(stu))

      const res = await orderStuAddMod(stu)
      if (res.success) {
        // 清除
        // this.props.form.resetFields()
        this.setState({
          visible: false,
        })
        setTimeout(() => {
          this.props.onCourseChange(stu)
        }, 500)
      } else {
        message.error(res.msg || '编辑学员失败')
      }
    } else {
      // 非编辑
      const values = this.props.form.getFieldsValue()
      console.log('values1:' + JSON.stringify(values))

      if (!values.phone) {
        message.error('手机不能为空')
        return
      }
      if (!values.stuName) {
        message.error('姓名不能为空')
        return
      }
      if (!values.gender) {
        message.error('性别不能为空')
        return
      }
      if (!values.cardType) {
        message.error('证件类型不能为空')
        return
      }
      if (!values.cardID) {
        message.error('证件号码不能为空')
        return
      }
      if (!values.compName) {
        message.error('企业名称不能为空')
        return
      }
      if (!values.compCode) {
        message.error('税号不能为空')
        return
      }
      if (!values.address) {
        message.error('公司地址不能为空')
        return
      }

      let files = ''
      if (this.state.attachList.length > 0) {
        this.state.attachList.forEach((attach: any) => {
          files += attach.baseServiURL0 + attach.directURL + ','
        })
        files = files.substr(0, files.length - 1)
      }
      const stu = {
        ...this.props.item,
        ...values,
        regiProvinId: this.state.regiProvinId,
        regiCityId: this.state.regiCityId,
        provinName: this.state.provinName,
        cityName: this.state.cityName,
        compId: this.state.compId,
        compName: this.state.compName,
        phone: values.phone,
        countrName: this.state.countrName,
        regiCountrId: this.state.regiCountrId,
        files: files.length > 0 ? files : undefined,
      }
      console.log('stu1:' + JSON.stringify(stu))

      // 清除
      // this.props.form.resetFields()
      this.setState({
        visible: false,
      })
      setTimeout(() => {
        this.props.onCourseChange(stu)
      }, 500)
    }
  }

  handleCancel = (_e: any) => {
    this.setState({
      visible: false,
    })
  }

  debounce = (fun: any, wait: any) => {
    return (...rest: any) => {
      let args = rest
      console.log(this.timerId)
      if (this.timerId) {
        clearTimeout(this.timerId)
      }
      this.timerId = setTimeout(() => {
        fun(args)
      }, wait)
    }
  }

  handlePreviewCancel = () => this.setState({ previewVisible: false })

  handleAttachUploadFile = (e: any) => {
    const attachList = [...this.state.attachList, ...e]
    if (attachList.length > 5) {
      message.warn('最多只能添加5个附件哦！')
      return
    }
    this.setState({
      attachList: attachList,
    })
  }

  // 方法：图片预览
  handlePreview = (file: any) => {
    console.log('preview', file)
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    })
  }

  handleChange = ({ fileList }: any) => {
    let attachList: any[] = []
    fileList.forEach((item: any) => {
      if (item.response && item.response.msgStatsNum) {
        attachList.push(item.response.rows[0])
      }
    })
    this.setState({
      attachList: attachList,
      fileList: fileList,
    })
  }

  handleBeforeUpload = (file: any) => {
    // 限制图片 格式、size、分辨率
    const isJPG = file.type === 'image/jpeg'
    const isJPEG = file.type === 'image/jpeg'
    // const isGIF = file.type === 'image/gif'
    const isPNG = file.type === 'image/png'
    const isLt2M = file.size / 1024 / 1024 < 1
    if (!(isJPG || isJPEG || isPNG)) {
      Modal.error({
        title: '只能上传JPG、JPEG、PNG格式的图片~',
      })
    } else if (!isLt2M) {
      Modal.error({
        title: '图片超过1M限制，不允许上传~',
      })
    }

    return (isJPG || isJPEG || isPNG) && isLt2M
  }
  handleSelectStu = (stuName: any) => {}

  inputValue = async (phone: any) => {
    const res = await studentList({
      phone: phone,
      page: 1,
      rows: 99,
    })
    let stuOne = res.data.length > 0 && res.data[0]
    this.props.form.setFieldsValue({
      ...stuOne,
    })
    stuOne.cardType = !stuOne.cardType ? '1' : stuOne.cardType
    this.setState({
      ...stuOne,
    })
  }
  onDebounceClick = (e: any) => {
    let debounceKeyup = this.debounce(this.inputValue, 300)
    debounceKeyup(e.target.value)
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    const form = this.props.form!
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    )
    const uploadProps = {
      name: 'file',
      multiple: true,
      action: BASE_URL + 'communalcs/upload',
      onChange: (info: any) => {
        const { status, response } = info.file
        if (response && response.msgStatsNum !== 1) {
          message.error(response.msg)
        } else {
          if (status === 'done') {
            message.success(`${info.file.name} 上传成功.`)
            let fileList: any[] = []
            info.fileList.forEach((item: any) => {
              if (item.response && item.response.msgStatsNum) {
                fileList.push(item.response.rows[0])
              }
            })

            //  this.handleFileChange(info)
          } else if (status === 'error') {
            message.error(`${info.file.name} 上传失败.`)
          }
        }
      },
    }
    return (
      <div>
        <Modal
          title={
            this.props.type == 'add'
              ? '新增学员'
              : this.props.type == 'edit'
              ? '编辑学员'
              : '添加自己'
          }
          visible={this.state.visible}
          width={'45%'}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          {
            <Form style={{ marginBottom: 16 }} {...formItemLayout}>
              <Row className="flex-wrapper" gutter={24}>
                <Col span={8}>
                  <PhoneInput
                    form={form}
                    labelname={'手机:'}
                    phone={this.state.phone}
                    onChange={(values: any) => {
                      if (values.stuOne) {
                        setTimeout(() => {
                          this.setState({
                            ...values.stuOne,
                          })
                        }, 500)
                        setTimeout(() => {
                          this.props.form.setFieldsValue({
                            ...values.stuOne,
                          })
                        }, 1500)
                      }
                    }}
                  />
                  {/*
                  <Input placeholder="手机" onKeyUp={this.onDebounceClick} allowClear />
                  */}
                </Col>
                <Col span={8}>
                  <Form.Item label="姓名" className="flex-wrapper">
                    {getFieldDecorator('stuName', {
                      rules: [{ required: true, message: '请输入姓名!' }],
                    })(<Input placeholder="姓名" allowClear />)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="性别" className="flex-wrapper">
                    {getFieldDecorator('gender', {
                      rules: [{ required: true, message: '请选择性别!' }],
                    })(
                      <Radio.Group defaultValue={this.state.gender} onChange={(e) => {}}>
                        <Radio value={'男'} key={'男'}>
                          男
                        </Radio>
                        <Radio value={'女'} key={'女'}>
                          女
                        </Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row className="flex-wrapper" gutter={24}>
                <Col span={12}>
                  <Form.Item label="证件类型" labelCol={{ sm: { span: 5 } }}>
                    {getFieldDecorator('cardType', {
                      rules: [{ required: true, message: '请选择证件类型!' }],
                    })(
                      <Select style={{ width: 120 }} allowClear>
                        <Option key="身份证" value="1">
                          身份证
                        </Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="证件号码">
                    {getFieldDecorator('cardID', {
                      rules: [
                        { required: true, message: '请选择证件类型!' },
                        {
                          pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                          message: '请输入正确的身份证号!',
                        },
                      ],
                    })(<Input placeholder="证件号码" allowClear />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row className="flex-wrapper" gutter={24}>
                <Col span={12}>
                  <CompInput
                    form={form}
                    formLayout={{ labelCol: { sm: { span: 5 } } }}
                    labelname={'企业名称:'}
                    name={this.state.compName}
                    id={this.state.compId}
                    onChange={(values: any) => {
                      if (values.compData) {
                        setTimeout(() => {
                          this.setState({
                            compId: values.compData.compId,
                            compName: values.compData.compName,
                            compCode: values.compData.compCode,
                            address: values.compData.address,
                          })
                        }, 500)
                        setTimeout(() => {
                          this.props.form.setFieldsValue({
                            compCode: values.compData.compCode,
                            address: values.compData.address,
                          })
                        }, 1500)
                      }
                    }}
                  />
                </Col>
                <Col span={12}>
                  <Form.Item label="税号">
                    {getFieldDecorator('compCode', {
                      rules: [{ required: true, message: '请输入税号!' }],
                    })(<Input placeholder="税号" allowClear />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row className="flex-wrapper" gutter={24}>
                <Col span={12}>
                  <Form.Item label="公司地址" labelCol={{ sm: { span: 5 } }}>
                    {getFieldDecorator('address', {
                      rules: [{ required: true, message: '请输入公司地址!' }],
                    })(<Input placeholder="公司地址" allowClear />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="报名时间">
                    <Input
                      readOnly={true}
                      placeholder="报名时间"
                      value={moment().format('YYYY-MM-DD HH:mm:ss')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="flex-wrapper" gutter={24}>
                <Col span={12}>
                  <Form.Item label="电子邮箱" labelCol={{ sm: { span: 5 } }}>
                    {getFieldDecorator('email')(<Input placeholder="电子邮箱" allowClear />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="所在省市区">
                    {getFieldDecorator('region')(
                      <GlobalRegionCascader
                        maxLevel={3}
                        regiProvinId={this.state.regiProvinId}
                        provinName={this.state.provinName}
                        regiCityId={this.state.regiCityId}
                        cityName={this.state.cityName}
                        regiCountrId={this.state.regiCountrId}
                        countrName={this.state.countrName}
                        onChange={(e: any) => {
                          if (e.length > 0) {
                            const value = e[0]
                            this.setState({
                              regiProvinId: value.value,
                              provinName: value.label,
                            })
                          }
                          if (e.length > 1) {
                            const value = e[1]
                            this.setState({
                              regiCityId: value.value,
                              cityName: value.label,
                            })
                          }
                          if (e.length > 2) {
                            const value = e[2]
                            this.setState({
                              regiCountrId: value.value,
                              countrName: value.label,
                            })
                          }
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {this.props.isCcieNo && (
                <Row className="flex-wrapper" gutter={24}>
                  <Col span={12}>
                    <Form.Item label="原证件编号:" labelCol={{ sm: { span: 5 } }}>
                      {getFieldDecorator('originalCcieNo', {
                        rules: [{ required: true, message: '请输入原证件编号!' }],
                      })(<Input placeholder="原证件编号" allowClear />)}
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form>
          }
          {this.props.isFile && <div className="ant-form-item-label">添加附件:</div>}
          {this.props.isFile && (
            <Card className="mt-m">
              {/*
              <Form.Item {...formItemLayout} label="添加附件">
                  {getFieldDecorator('attachList',{initialValue:this.state.attachList,valuePropName: 'file',
                    rules: [{ required: false, message: '请上传附件!' }]
                  })
                  (
               */}
              <div>
                <Upload
                  {...uploadProps}
                  name="file"
                  listType="picture-card"
                  onPreview={this.handlePreview}
                  onChange={this.handleChange}
                  fileList={this.state.fileList}
                  accept=".jpg,.png,.gif,.jpeg"
                  beforeUpload={this.handleBeforeUpload}
                >
                  {
                    /*
                  {this.props.tAccessory >= 6 ? null : uploadButton}
                  */ uploadButton
                  }
                </Upload>
                <Modal
                  visible={this.state.previewVisible}
                  footer={null}
                  onCancel={this.handlePreviewCancel}
                >
                  <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
              </div>
              {/*  )}</Form.Item>*/}
              {/*
              <DocUploader
                  buttonTitle="添加附件"
                  buttonSize="small"
                  accept=".doc,.docx,.xls,xlsx,.pdf"
                  handleUploadFileChange={this.handleAttachUploadFile}
                />
                <List
                  size="small"
                  dataSource={this.state.attachList}
                  renderItem={(item: any) => (
                    <List.Item>
                      <span>{item.origFileName}</span>
                      <a href={BASE_URL + item.attachDirectURL} target="_blank">
                        下载
                      </a>
                    </List.Item>
                  )}
                ></List>
                */}
            </Card>
          )}
        </Modal>
        {this.props.isDelEdit && (
          <Button
            className="mr-m"
            type={this.props.type == 'add' ? 'primary' : 'link'}
            onClick={() => {
              this.setState({
                visible: true,
              })
            }}
          >
            {this.props.type === 'add' && '添加学员'}
            {this.props.type === 'edit' && '编辑'}
            {this.props.type === 'addself' && '添加自己'}
          </Button>
        )}
      </div>
    )
  }
}

export default Form.create<IApplyStuEditProps>()(ApplyStuEdit)
