/**
 * Created by hao.cheng on 2017/4/13.
 */
import React, { Component } from 'react'
import screenfull from 'screenfull'
import { Menu, Icon, Layout, Modal } from 'antd'
import { Link } from 'react-router-dom'
import { queryString } from '../utils'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connectAlita } from 'redux-alita'
import umbrella from 'umbrella-storage'
import { TITLE, LOGO_NAME } from './../service/config'
import routes from '../routes/config'
import { IFMenu } from '../routes/config'
import { hasLogin, getUrlKey } from '../utils'
import { autoLoginBySessionId } from './../service/index'
import Login from './pages/Login'

const { Header } = Layout
const SubMenu = Menu.SubMenu
const MenuItemGroup = Menu.ItemGroup

type HeaderCustomProps = RouteComponentProps<any> & {
  toggle: () => void
  collapsed: boolean
  user: any
  responsive?: any
  path?: string
}
type HeaderCustomState = {
  user: any
  visible: boolean
  menuKeys: any[]
}

class HeaderCustom extends Component<HeaderCustomProps, HeaderCustomState> {
  constructor(props: any, context: any) {
    super(props)
    this.state = {
      user: '',
      visible: false,
      menuKeys: [],
    }
  }
  componentDidMount() {
    const QueryString = queryString() as any
    const RSESSIONID = getUrlKey('RSESSIONID')
    const Authorization = getUrlKey('Authorization')
    if (Authorization) {
      umbrella.setLocalStorage('token', Authorization || '')
    }
    if (RSESSIONID) {
      umbrella.setLocalStorage('rsessionid', RSESSIONID || '')
      const res = this.loginBySessionId(RSESSIONID)
    }

    let storageUser = umbrella.getLocalStorage('user')
    // _user = (storageUser && JSON.parse(storageUser)) || '测试';
    if (!storageUser && QueryString.hasOwnProperty('code')) {
      // gitOauthLogin(QueryString.code).then((res: any) => {
      //   this.setState({
      //     user: res,
      //   })
      //   umbrella.setLocalStorage('user', res)
      // })
    } else {
      this.setState({
        user: storageUser,
      })
    }

    const menuKeys = routes.menus.map((item) => item.key)
    this.setState({
      menuKeys,
    })
  }

  loginBySessionId = async (sessionId: any) => {
    const res = await autoLoginBySessionId(sessionId)
    const result = res.data[0]
    if (result) {
      const userJson = result.userJson
      const userInfo = JSON.parse(userJson)
      console.log(userInfo)
      if (userInfo) {
        umbrella.setLocalStorage('user', userInfo)
        this.setState({
          user: userInfo,
        })
      }
    }
    return res
  }
  screenFull = () => {
    if (screenfull.isEnabled) {
      screenfull.request()
    }
  }
  menuClick = (e: { key: string }) => {
    e.key === 'logout' && this.logout()
  }

  logout = () => {
    umbrella.removeLocalStorage('user')
    setTimeout(() => {
      window.location.reload()
    }, 500)
    setTimeout(() => {
      const { history } = this.props
      history.push('/')
    }, 500)
  }
  popoverHide = () => {
    this.setState({
      visible: false,
    })
  }

  handleVisibleChange = (visible: boolean) => {
    this.setState({ visible })
  }

  render() {
    const { location } = this.props
    const { pathname } = location

    const selectMenu = routes.menus.find((item) => pathname.indexOf(item.key) != -1)
    console.log(selectMenu ? selectMenu!!.key : '')
    const headerClass =
      'custom-theme layout-header' + (selectMenu?.key == '/app/home' ? ' home-header' : '')
    const titleDiv = <span className="title">{TITLE}</span>

    return (
      <Header className={headerClass}>
        <div className="header-main">
          <div className="logo" style={{ flex: '1' }}>
            <img src={LOGO_NAME} alt="" />
            {titleDiv}
          </div>
          <Menu
            mode="horizontal"
            style={{ lineHeight: '64px', float: 'right' }}
            onClick={this.menuClick}
            selectedKeys={[selectMenu ? selectMenu.key : '']}
          >
            {routes.menus.map((menu: IFMenu) => (
              <Menu.Item key={menu.key}>
                <Link to={(menu.route || menu.key) + (menu.query || '')}>
                  {menu.icon && <Icon type={menu.icon} />}
                  {menu.title}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
          <Menu
            mode="horizontal"
            style={{ lineHeight: '64px', float: 'right' }}
            onClick={this.menuClick}
          >
            <SubMenu
              title={
                <span className="avatar">
                  <div className="avatar-name">
                    {this.props.user.loginName ? this.props.user.loginName : '请登录'}
                  </div>
                  <i className="on bottom b-white" />
                </span>
              }
            >
              <MenuItemGroup title="操作">
                {hasLogin() && (
                  <Menu.Item key="logout">
                    <span onClick={this.logout}>退出登录</span>
                  </Menu.Item>
                )}
                {!hasLogin() && (
                  <Menu.Item key="login">
                    <Login buttonType="link" />
                  </Menu.Item>
                )}
              </MenuItemGroup>
            </SubMenu>
          </Menu>
        </div>
      </Header>
    )
  }
}

// 重新设置连接之后组件的关联类型
const HeaderCustomConnect: React.ComponentClass<
  HeaderCustomProps,
  HeaderCustomState
> = connectAlita([{ responsive: { isMobile: false } }])(HeaderCustom)

export default withRouter(HeaderCustomConnect)
