import * as React from 'react'
import Breadcrumb from '../Breadcrumb'
import {
  Input,
  InputNumber,
  Divider,
  Form,
  Checkbox,
  Button,
  message,
  Row,
  Col,
  Table,
  Modal,
} from 'antd'
import {
  createOrder,
  trainOne,
  planOne,
  courseOne,
  orderStuAdd,
  orderStuDel,
  userDetail,
  courseSettingList,
} from '../../service/index'
import { BASE_URL, IMAGE_SERVER, TITLE } from '../../service/config'
import { FormComponentProps } from 'antd/lib/form'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import umbrella from 'umbrella-storage'
import ApplyStuEdit from './ApplyStuEdit'
import moment from 'moment'
import noImage from '../../style/imgs/no_image.png'
import { studentList } from '../../service'

const { Column } = Table

export interface IOrderConfirmProps extends FormComponentProps, RouteComponentProps<any> {
  type: any
  prodId: any
}
interface IOrderConfirmStates {
  buyNum: any
  totalPrice: any
  id: any
  type: any
  order: any
  stu: any
  agreeProtocol: any
  loading: boolean
  applyStuList: any[]
  imgsVisible: any
  previewVisible: any
  previewImage: any
  isCcieNo: any
  isFile: any
  currentFiles: any

  submiting: any
}

class OrderConfirm extends React.Component<IOrderConfirmProps, IOrderConfirmStates> {
  constructor(props: any) {
    super(props)
    const { id, type } = props.query
    let stu = {}

    this.confirmOrder = this.confirmOrder.bind(this)
    let user = umbrella.getLocalStorage('user')
    if (user && user.studentJson) {
      stu = user.studentJson[0]
    } else {
      message.warn('您还未登录！')
      return
    }

    this.state = {
      order: {},
      buyNum: 0,
      totalPrice: 0,
      id,
      type,
      stu: stu,
      agreeProtocol: false,
      loading: false,
      applyStuList: [],
      imgsVisible: false,
      previewVisible: false,
      previewImage: '',
      isCcieNo: false,
      isFile: false,
      currentFiles: '',
      submiting: false,
    }
  }
  componentDidMount() {
    const { type, stu } = this.state
    if (type === 'train') {
      this.getTrain()
    } else if (type === 'course') {
      this.getCourse()
    } else if (type === 'plan') {
      this.getPlan()
    }

    setTimeout(() => {
      this.props.form.setFieldsValue({
        compName: stu.compName,
        linkPerson: stu.stuName,
        linkPhone: stu.phone,
      })
    }, 500)
  }

  async getTrain() {
    const params = { itemId: this.state.id }
    const res = await trainOne(params)

    if (res.success) {
      const train = res.data[0]
      const price = train.prefePrice || train.price || 0
      let setJsonStr = res.data[0].itemSetJson
      let order = {
        ...train,
        prefePrice: price,
      }
      order.prefePrice = price || 0

      this.props.form.setFieldsValue({ ...order, enrollCount: 1 })
      if (setJsonStr) {
        setJsonStr = setJsonStr.replace(/=/g, ':')
        let setArr = setJsonStr.split('},')
        this.setParam(setArr)
      }
      this.setState({ order: order })
      this.calcTotalPrice(1)
    }
  }
  async getPlan() {
    const params = { planId: this.state.id }
    const res = await planOne(params)

    if (res.success) {
      const plan = res.data[0]
      const price = plan.prefePrice || plan.price || 0
      let setJsonStr = res.data[0].planSetJson
      let order = {
        ...plan,
        prefePrice: price,
      }
      order.prefePrice = price || 0

      this.props.form.setFieldsValue({ ...order, enrollCount: 1 })
      if (setJsonStr) {
        setJsonStr = setJsonStr.replace(/=/g, ':')
        let setArr = setJsonStr.split('},')
        this.setParam(setArr)
      }
      this.setState({ order: order })
      this.calcTotalPrice(1)
    }
  }

  async getCourse() {
    const params = { courseId: this.state.id }
    const res = await courseOne(params)

    if (res.success) {
      const course = res.data[0]
      let setJsonStr = res.data[0].courseSetJson
      const price = course.prefePrice || course.price || 0
      let order = {
        ...course,
        prefePrice: price,
      }
      order.prefePrice = price || 0

      this.props.form.setFieldsValue({ ...order, enrollCount: 1 })
      if (setJsonStr) {
        setJsonStr = setJsonStr.replace(/=/g, ':')
        let setArr = setJsonStr.split('},')
        this.setParam(setArr)
      }
      this.setState({
        order: order,
      })
      this.calcTotalPrice(1)
    }
  }

  async confirmOrder() {
    if (this.state.submiting) {
      message.warn('订单正在处理中，请勿重复提交')
      return
    }
    // 锁死当前按钮不让操作
    this.setState({
      submiting: true,
    })
    this.props.form!.validateFields(async (err, values) => {
      if (!err) {
        if (!this.state.applyStuList || this.state.applyStuList.length == 0) {
          message.error('报名人员列表不能为空')
          return
        }

        let orderType = 0
        switch (this.state.type) {
          case 'train':
            orderType = 2
            break
          case 'course':
            orderType = 1
            break
          case 'plan':
            orderType = 3
            break
          default:
            orderType = 0
            break
        }
        const params = {
          orderType: orderType,
          orderTitle: this.state.order.title,
          orderTypeId: this.state.id,
          ...values,
          unitPrice: this.state.order.prefePrice,
          totalAmount: this.state.totalPrice,
          orderStuJson: JSON.stringify(this.state.applyStuList),
        }
        const res = await createOrder(params)

        if (res.success) {
          const order = res.data[0]
          const orderId = order.orderId
          this.props.history.push(
            '/app/user/orderDetail?orderId=' + orderId + '&courseId=' + this.state.id
          )
        } else {
          message.error(res.msg || '报名失败')
        }
        this.setState({
          submiting: false,
        })
      }
    })
  }

  calcTotalPrice(value: any) {
    setTimeout(() => {
      const { order } = this.state
      this.setState({
        buyNum: value ? value : 0,
        totalPrice: (value ? value : 0) * (order.prefePrice ? order.prefePrice : 0),
      })
    }, 300)
  }
  setParam(setArr: any) {
    const settings = setArr.map((setStr: any, index: any) => {
      let set = index == setArr.length - 1 ? setStr : setStr + '}'
      set = set
        .replace(/{/g, '{"')
        .replace(/:/g, '":"')
        .replace(/,/g, '","')
        .replace(/}/g, '"}')
        .replace(/}"/g, '}')
        .replace(/:"{/g, ':{')

      return JSON.parse(set)
    })
    if (settings) {
      settings.forEach((setting: any) => {
        if (setting.title == 'isCcieNo') {
          this.setState({
            isCcieNo: setting.option0 == 0 ? false : true,
          })
        } else if (setting.title == 'isFile') {
          this.setState({
            isFile: setting.option0 == 0 ? false : true,
          })
        }
      })
    }
  }

  clickCompName = async () => {
    const { stu } = this.state

    if(stu && !(stu.compName)) {
      let user = umbrella.getLocalStorage('user')

      const res = await studentList({
        phone: user.phone,
        page: 1,
        rows: 2,
      })
      if(res && res.data && res.data.length > 0) {
        let stuOne = res.data.length > 0 && res.data[0]
        this.setState({
          stu: stuOne
        })
        this.props.form.setFieldsValue({
          compName: stuOne.compName,
          linkPerson: stuOne.stuName,
          linkPhone: stuOne.phone,
        })
      }
    } else if(stu && stu.compName) {
      this.props.form.setFieldsValue({
        compName: stu.compName,
        linkPerson: stu.stuName,
        linkPhone: stu.phone,
      })
    }
  }

  public render() {
    const { order } = this.state
    const { getFieldDecorator } = this.props.form!
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }

    return (
      <div>
        <div className="app_layout_main">
          <Breadcrumb />
          <div className="course_detail_body">
            <div className="flex">
              <div className="course-box">
                <img
                  className="img"
                  src={order.coverDirectURL ? IMAGE_SERVER + order.coverDirectURL : noImage}
                  alt=""
                />
              </div>
              <div className="course-box ml-m">
                <div className="title">{order.title}</div>
                <div className="detail">
                  <div>
                    <span>
                      {order.enrollStartDate} / {order.enrollEndDate}
                    </span>
                    {order.enrollSub != null && (
                      <span className="ml-m">
                        名额剩余：{order.enrollSub == '-1' ? '不限' : order.enrollSub}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Form
                {...formItemLayout}
                style={{ marginBottom: 16, width: '50%' }}
                title="联络人信息"
              >
                <Divider>报名信息</Divider>
                <Row className="flex-wrapper" gutter={24}>
                  <Col span={8}>
                    <Form.Item label="单价">
                      <span className="price text">￥{this.state.order.prefePrice || 0}</span>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="报名人数">
                      {getFieldDecorator('enrollCount')(
                        <InputNumber
                          min={1}
                          max={10}
                          onChange={(value: any) => {
                            this.calcTotalPrice(value)
                          }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="总价">
                      <span className="price text">￥{this.state.totalPrice || 0}</span>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider>联络人信息</Divider>
                <Form.Item
                  label="单位名称"
                  labelCol={{ sm: { span: 3 } }}
                  wrapperCol={{ sm: { span: 12 } }}
                >
                  {getFieldDecorator('compName', {
                    rules: [{ required: true, message: '请输入单位名称!' }],
                  })(<Input placeholder="单位名称" allowClear readOnly={true} onClick={this.clickCompName} />)}
                </Form.Item>
                <Form.Item
                  label="联系人"
                  labelCol={{ sm: { span: 3 } }}
                  wrapperCol={{ sm: { span: 12 } }}
                >
                  {getFieldDecorator('linkPerson', {
                    rules: [{ required: true, message: '请输入联系人!' }],
                  })(<Input placeholder="联系人" allowClear />)}
                </Form.Item>
                <Form.Item
                  label="联系电话"
                  labelCol={{ sm: { span: 3 } }}
                  wrapperCol={{ sm: { span: 12 } }}
                >
                  {getFieldDecorator('linkPhone', {
                    rules: [{ required: true, message: '请输入联系电话!' }],
                  })(<Input placeholder="联系电话" allowClear />)}
                </Form.Item>
                <Form.Item
                  {...tailFormItemLayout}
                  labelCol={{ sm: { span: 3 } }}
                  wrapperCol={{ sm: { span: 12 } }}
                >
                  {getFieldDecorator('agree')(
                    <Checkbox
                      onChange={(e) => {
                        console.log(e)
                        const value = e.target.checked
                        this.setState({
                          agreeProtocol: value,
                        })
                      }}
                    >
                      同意
                      <a href="assets/orderProtocol.html" target="_blank">
                        &nbsp; {TITLE}协议
                      </a>
                    </Checkbox>
                  )}
                </Form.Item>
              </Form>
              <Divider></Divider>
              <div className="flex-vcenter mt-m">
                <ApplyStuEdit
                  type="addself"
                  item={this.state.stu}
                  isFile={this.state.isFile}
                  isDelEdit={true}
                  isCcieNo={this.state.isCcieNo}
                  onCourseChange={async (stu: any) => {
                    const applyStuList: any[] = this.state.applyStuList

                    if (
                      this.state.order.enrollCount > 0 &&
                      applyStuList.length >= this.state.order.enrollCount
                    ) {
                      message.warn('已超出报名人数限制')
                      return
                    }

                    let isExist = false
                    applyStuList.forEach((item) => {
                      if (item.phone === stu.phone) {
                        isExist = true
                      }
                    })
                    if (!isExist) {
                      applyStuList.push(stu)
                    }

                    this.setState({
                      applyStuList: applyStuList,
                    })
                  }}
                />
                <ApplyStuEdit
                  type="add"
                  courseId={this.state.id}
                  isFile={this.state.isFile}
                  isDelEdit={true}
                  isCcieNo={this.state.isCcieNo}
                  onCourseChange={async (stu: any) => {
                    const applyStuList: any[] = this.state.applyStuList

                    if (
                      this.state.order.enrollCount > 0 &&
                      applyStuList.length >= this.state.order.enrollCount
                    ) {
                      message.warn('已超出报名人数限制')
                      return
                    }

                    let isExist = false
                    applyStuList.forEach((item) => {
                      if (item.phone === stu.phone) {
                        isExist = true
                      }
                    })
                    if (!isExist) {
                      applyStuList.push(stu)
                    }

                    this.setState({
                      applyStuList: applyStuList,
                    })
                  }}
                />
              </div>
              <Table
                className="mt-m"
                rowKey="itemId"
                size="small"
                loading={this.state.loading}
                dataSource={this.state.applyStuList}
              >
                <Column
                  title="序号"
                  dataIndex="name"
                  key="name"
                  render={(name: any, row: any, index) => index + 1}
                />
                <Column
                  title="姓名"
                  dataIndex="stuName"
                  key="stuName"
                  render={(stuName: any) => stuName}
                />
                <Column
                  title="身份证号"
                  dataIndex="cardID"
                  key="cardID"
                  render={(cardID: any) => cardID}
                />
                <Column
                  title="单位名称"
                  dataIndex="compName"
                  key="compName"
                  render={(compName: any) => compName}
                />
                <Column title="电话" dataIndex="phone" key="phone" render={(phone: any) => phone} />
                <Column
                  title="组织机构代码"
                  dataIndex="compCode"
                  key="compCode"
                  render={(compCode: any) => compCode}
                />
                <Column
                  title="附件"
                  dataIndex="files"
                  key="files"
                  render={(files: any) => (
                    /*    <ul>
                      {files &&
                        files.map((item, index) => (
                          <li key={item.id}>
                            <span>{item.origFileName}</span>
                            <a
                              href={BASE_URL + item.attachDirectURL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              下载
                            </a>
                          </li>
                        ))}
                    </ul>*/
                    <div>
                      <Button
                        size="small"
                        icon="import"
                        type="primary"
                        onClick={() => {
                          this.setState({
                            imgsVisible: true,
                            currentFiles: files,
                          })
                        }}
                      >
                        {'查看'}
                      </Button>
                    </div>
                  )}
                />
                {/*this.state.order.orderStatus === 2 &&*/}
                {
                  <Column
                    title="操作"
                    dataIndex="itemId"
                    key="itemId"
                    render={(itemId: any, row: any, index: number) => (
                      <div className="flex">
                        <ApplyStuEdit
                          type="edit"
                          isFile={this.state.isFile}
                          isDelEdit={true}
                          isCcieNo={this.state.isCcieNo}
                          courseId={this.state.id}
                          item={row}
                          onCourseChange={async (stu: any) => {
                            const applyStuList: any[] = this.state.applyStuList

                            const oldStu = applyStuList[index]
                            applyStuList[index] = { ...oldStu, ...stu }

                            if (
                              this.state.order.enrollCount > 0 &&
                              applyStuList.length > this.state.order.enrollCount
                            ) {
                              message.warn('已超出报名人数限制')
                              return
                            }

                            this.setState({
                              applyStuList: applyStuList,
                            })
                          }}
                        />
                        <Button
                          type="link"
                          color="red"
                          onClick={async () => {
                            if (row.orderStuId) {
                              const res = await orderStuDel(row.orderStuId)
                              if (res.success) {
                                //  this.getOrder()
                              } else {
                                message.error(res.msg)
                              }
                            } else {
                              const { applyStuList } = this.state
                              applyStuList.splice(index, 1)
                              this.setState({
                                applyStuList: applyStuList,
                              })
                            }

                            // const { applyStuList } = this.state
                            // applyStuList.splice(index, 1)
                            // this.setState({
                            //   applyStuList: applyStuList,
                            // })
                          }}
                        >
                          删除
                        </Button>
                      </div>
                    )}
                  />
                }
              </Table>
              <Modal
                title="附件"
                width={'60%'}
                visible={this.state.imgsVisible}
                footer={null}
                onCancel={() => {
                  this.setState({
                    imgsVisible: false,
                  })
                }}
              >
                <div className="panel-body">
                  {this.state.currentFiles &&
                    this.state.currentFiles.length > 0 &&
                    this.state.currentFiles.split(',').map((url: any, index: any) => {
                      const startIndex = url.indexOf('/upload/')
                      const title = url.substring(startIndex + url.length)
                      return (
                        <div
                          className="course-box mr-m"
                          title={title}
                          key={index}
                          onClick={() => {
                            this.setState({
                              previewImage: url,
                              previewVisible: true,
                            })
                          }}
                        >
                          <img className="img" src={url} alt="" />
                          <div className="title">{title}</div>
                        </div>
                      )
                    })}
                </div>
              </Modal>
              <Modal
                visible={this.state.previewVisible}
                footer={null}
                onCancel={() => this.setState({ previewVisible: false })}
              >
                <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
              </Modal>
              <div className="flex-vcenter mt-m">
                <Button
                  type="primary"
                  disabled={!this.state.agreeProtocol}
                  onClick={() => {
                    this.confirmOrder()
                  }}
                >
                  提交报名
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Form.create<IOrderConfirmProps>()(withRouter(OrderConfirm))
