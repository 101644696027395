import * as React from 'react'
import { Form, Select } from 'antd'
import { studentList } from '../../service/index'
const { Option } = Select
let timeout: any = null
export interface IPhoneInputProps {
  form: any
  onChange: any
  phone: any
  style?: any
  defaultData?: any
  labelname?: any
  formLayout?: any
}

type IPhoneInputStates = {
  data: any
  value: any
}

export default class CompInput extends React.Component<IPhoneInputProps, IPhoneInputStates> {
  constructor(props: any) {
    super(props)

    if (props && props.phone) {
      this.state = {
        data: [{ value: props.phone, text: props.phone, stuOne: {} }],
        value: props.phone,
      }
    } else {
      this.state = {
        data: [],
        value: undefined,
      }
    }
  }

  componentDidMount() {
    if (this.props.phone) {
      setTimeout(() => {
        this.props.form.setFieldsValue({
          phone: this.props.phone,
        })
      }, 100)
    }
  }

  componentDidUpdate(props: any) {
    if (props.phone && props.phone != this.props.phone) {
      setTimeout(() => {
        this.props.form.setFieldsValue({
          phone: props.phone,
        })
      }, 100)
    }
  }

  searchPhone = async (str: any) => {
    const params = {
      phone: str,
    }
    const res = await studentList(params)
    if (res.success && res.data.length > 0) {
      const result = res.data.map((item: any) => {
        return {
          value: item.phone,
          text: item.phone,
          stuOne: item,
        }
      })
      return result
    }
    return []
  }

  fetch = async (value: any, callback: any) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    const fake = async () => {
      const data = await this.searchPhone(value)
      callback(data)
    }
    timeout = setTimeout(fake, 300)
  }

  handleSearch = (value: any) => {
    if (value) {
      this.fetch(value, (data: any) => {
        if (data.length > 0) {
          this.setState({
            data,
          })
        } else {
          this.props.form.setFieldsValue({
            phone: value,
          })
        }
      })
    } else {
      this.setState({ data: [] })
    }
  }

  handleChange = (value: any, option: any) => {
    this.setState({ value })
    if (this.props.onChange) {
      const stu = this.state.data.find((item: any) => item.text == value)
      if (stu) {
        this.props.onChange({ phone: stu.phone, stuOne: stu.stuOne }, option.props.children)
      } else {
        this.props.onChange({ phone: value })
      }
    }
  }

  public render() {
    const { getFieldDecorator } = this.props.form!
    return (
      <Form.Item label={this.props.labelname || '手机:'} {...this.props.formLayout}>
        {getFieldDecorator('phone', {
          rules: [
            { required: true, message: '请输入手机!' },
            { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机!' },
          ],
          initialValue: this.props.phone || '',
        })(
          <Select
            showSearch
            placeholder="请输入手机"
            style={this.props.style}
            onSearch={this.handleSearch}
            onChange={this.handleChange}
          >
            {this.state.data &&
              this.state.data.map((d: any) => <Option key={d.text}>{d.value}</Option>)}
          </Select>
        )}
      </Form.Item>
    )
  }
}
